<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item >
					<el-input style="width: 140px;" v-model="params.keyword" placeholder="备注说明" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select style="width: 180px;" v-model="params.employees" placeholder="请选择员工" filterable  multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id">
							<span>{{employee.name}}</span>
							<span class="c9 fs12">{{counts["employee_"+employee.id] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getInsurances({ ...params, page: 1})" plain>查询</el-button>
					<el-button type="primary" @click="$refs.description.open()">添加</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<!-- <el-table ref="insurance_table" class="bill scroll-wrapper absolute" max-height="100%" :data="insurances" :size="theme.size" @sort-change="sortChange" show-summary>
					<el-table-column prop="employee.name" label="创建人" min-width="90"></el-table-column>
					<el-table-column prop="name" label="名称" min-width="160">
						<el-link slot-scope="scope" type="primary" :disabled="!$utils.update($api.URI_POSITIONS_DESCRIPTIONS)" @click="$refs['insurances'].open(scope.row)">{{scope.row.name}}</el-link>
					</el-table-column>
					<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
					<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
					<el-table-column label="操作" width="60">
						<template slot-scope="scope">
							<el-button type="text" :disabled="!$utils.delete($api.URI_POSITIONS_DESCRIPTIONS)" @click="delInsurance(scope.row)" v-if="!scope.row.deleted_at">删除</el-button>
							<el-button type="text" :disabled="!$utils.restore($api.URI_POSITIONS_DESCRIPTIONS)" @click="resInsurance(scope.row)" v-else>恢复</el-button>
						</template>
					</el-table-column>
				</el-table> -->
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getInsurances({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getInsurances({ ...params, page: v }) }"
			background>	
		</el-pagination>
		<template slot="other">
			<show-description ref="description"></show-description>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';
	import showDescription from './show';

	export default {
		components: {
			mainTableA,
			showDescription,
		},
		computed: {
			...mapState(['theme', 'admin_user']),
			insurances_cfg () {
				return this.$utils.uploadConfig('insurances');
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getInsurances(this.params);
			},
			delInsurance ({ id, name }) {
				this.$confirm(name, '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleInsurances({action: 'delete', id});
				});
			},
			resInsurance ({ id, name }) {
				this.$confirm(name, '确定要恢复吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleInsurances({action: 'restore', id});
				});
			},
			async getInsurances (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_POSITIONS_DESCRIPTIONS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.insurances = result.data;
				this.employees = result.employees;
				this.counts = result.counts;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async handleInsurances (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_POSITIONS_DESCRIPTIONS, {params, headers:  { loading }});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getInsurances(this.params);
				this.$message.success(msg);
			}
		},
		data() {
			return {
				counts: {},
				insurance: {},
				insurances: [],
				employees: [],
				params: {
					perPage: 10
				}
			}
		},
		created () {
			this.getInsurances(this.params, true);
		}
	};
</script>