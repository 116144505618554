<template>
	<el-dialog width="420px" title="岗位说明" :visible.sync="show_dialog" :fullscreen="theme.width < 680" :close-on-click-modal="false" append-to-body destroy-on-close>
		<el-form label-width="70px" ref="description_form" :model="description" :rules="rules" :size="theme.size" status-icon>
			<el-form-item label="名称" prop="name">
				<el-input v-model="description.name" placeholder="请输入名称" clearable></el-input>
			</el-form-item>
			<el-form-item label="备注说明" prop="comment">
				<el-input type="textarea" v-model="description.comment" :rows="3" placeholder="请输入备注说明"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('description_form')">{{description.id ? '提交保存' : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme'])
		},
		filters: {

		},
		methods: {
			async open (r) {
				this.description = {
					position_statements: [{indication: '', task: '', purpose: ''}],
					...r
				};
				this.show_dialog = true;
			},
			async submitDescriptions (data) {
				const res = await this.$http.post(this.$api.URI_POSITIONS_DESCRIPTIONS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.$emit('refresh');
						this.show_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'description_form':
							this.submitDescriptions(this.description);
						break;
					}
				});
			}
		},
		data() {
			return {
				description: {},
				show_dialog: false,
				rules: {
					name: [{ required: true, message: '必须填写名称', trigger: 'blur'}]
				}
			}
		}
	};
</script>